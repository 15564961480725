import request from "@/utils/request";

function companyAll(data) {
  return request({
    url: "/show/company/all/find.diti",
    method: "post",
    data,
  });
}

function companyAdd(data) {
  return request({
    url: "/show/company/add.diti",
    method: "post",
    data,
  });
}

function companyDel(data) {
  return request({
    url: "/show/company/del.diti",
    method: "post",
    data,
  });
}

function companyUpdate(data) {
  return request({
    url: "/show/company/update.diti",
    method: "post",
    data,
  });
}

function roomInit(data) {
  return request({
    url: "/show/room/init",
    method: "post",
    data,
  });
}

function junkWordsInit(data) {
  return request({
    url: "/show/junk/words/init",
    method: "post",
    data,
  });
}

export {
  companyAll,
  companyAdd,
  companyDel,
  companyUpdate,
  roomInit,
  junkWordsInit,
};
