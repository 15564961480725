<template>
  <div>
    <PagingQueryPage
      ref="PagingQueryPage"
      :queryFormState="queryFormState"
      size="middle"
      :bordered="true"
      rowKey="companyId"
      :columns="columns"
      :pagination="pagination"
      dataUrl="/show/company/all/find.diti"
      :requestOptions="requestOptions"
    >
      <template #query>
        <a-form-model-item label="关键词">
          <a-input v-model="queryFormState.keyword"></a-input>
        </a-form-model-item>
      </template>
      <a-button type="primary" icon="plus" slot="action" @click="add"
        >新增
      </a-button>
    </PagingQueryPage>
    <!-- 新增/编辑公司弹框 -->
    <a-modal
      :width="520"
      :visible="modalVisible"
      :title="(isAdd ? '新增' : '编辑') + modalTitle"
      ok-text="确认"
      cancel-text="取消"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="submit"
      @cancel="
        () => {
          modalVisible = false;
        }
      "
    >
      <a-form-model
        ref="modalForm"
        :model="modalFormState"
        :rules="modalRules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item label="公司名称" prop="companyName">
          <a-input v-model="modalFormState.companyName" />
        </a-form-model-item>
        <a-form-model-item label="联系人名称" prop="contactName">
          <a-input v-model="modalFormState.contactName" />
        </a-form-model-item>
        <a-form-model-item label="联系人电话" prop="contactMobile">
          <a-input v-model="modalFormState.contactMobile" />
        </a-form-model-item>
        <a-form-model-item label="直播间数量" prop="license">
          <a-input v-model="modalFormState.license" />
        </a-form-model-item>
        <a-form-model-item label="过期时间" prop="expireTime">
          <!-- <a-input v-model="modalFormState.expireTime" /> -->
          <a-date-picker
            :locale="locale"
            format="YYYY-MM-DD"
            v-model="modalFormState.expireTime"
          />
        </a-form-model-item>
        <a-form-model-item label="状态" prop="companyStatus">
          <a-select v-model="modalFormState.companyStatus">
            <a-select-option
              v-for="item in compnayStatusOptions"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="公司地址" prop="companyAddress">
          <a-textarea v-model="modalFormState.companyAddress" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-textarea v-model="modalFormState.remark" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import PagingQueryPage from "@/components/PagingQueryPage/diti.vue";
import { Modal, notification } from "ant-design-vue";
import {
  companyAdd,
  companyUpdate,
  companyDel,
  roomInit,
  junkWordsInit,
} from "@/apis/company";
import { compnayStatusOptions } from "@/config/enum";
import moment from "moment";
import "moment/dist/locale/zh-cn";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
// import { parseTime } from "@/utils/time";
export default {
  components: { PagingQueryPage },
  data() {
    return {
      locale,
      compnayStatusOptions,
      queryFormState: {
        keyword: "",
      },
      requestOptions: {},
      columns: [
        {
          title: "序号",
          dataIndex: "",
          width: 80,
          align: "center",
          fixed: "left",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "ID",
          dataIndex: "companyId",
          width: 200,
          align: "center",
        },
        {
          title: "公司名称",
          dataIndex: "companyName",
          width: 200,
          align: "center",
        },
        {
          title: "联系人名称",
          dataIndex: "contactName",
          width: 200,
          align: "center",
        },
        {
          title: "联系人电话",
          dataIndex: "contactMobile",
          width: 200,
          align: "center",
        },
        {
          title: "公司地址",
          dataIndex: "companyAddress",
          width: 200,
          align: "center",
        },
        {
          title: "状态",
          dataIndex: "companyStatus",
          width: 200,
          align: "center",
          customRender: (text) => {
            return compnayStatusOptions.find((r) => r.value === text)
              ? compnayStatusOptions.find((r) => r.value === text).label
              : "";
          },
        },
        {
          title: "备注",
          dataIndex: "remark",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "",
          width: 140,
          align: "center",
          fixed: "right",
          customRender: (record) => {
            return (
              <span class="action-group">
                <a-button
                  type="link"
                  onClick={() => {
                    this.edit(record);
                  }}
                >
                  编辑
                </a-button>
                <a-divider type="vertical" />
                <a-button
                  class="delBtn"
                  type="link"
                  onClick={() => {
                    this.del(record);
                  }}
                >
                  删除
                </a-button>
              </span>
            );
          },
        },
      ],
      pagination: {},
      modalVisible: false, // 弹窗状态
      isAdd: true, // 是否新增
      modalTitle: "公司", // 弹窗标题
      modalRules: {
        companyName: [
          { required: true, message: "公司名称必填", trigger: "blur" },
        ],
        contactName: [
          { required: true, message: "联系人名称必填", trigger: "blur" },
        ],
        contactMobile: [
          { required: true, message: "联系人电话必填", trigger: "blur" },
        ],
        license: [
          { required: true, message: "直播间数量必填", trigger: "blur" },
        ],
        expireTime: [
          { required: true, message: "过期时间必填", trigger: "blur" },
        ],
        companyStatus: [
          { required: true, message: "状态必填", trigger: "blur" },
        ],
      }, // 弹窗校验规则
      modalFormState: {
        companyId: "idGenerator",
        companyName: "",
        contactName: "",
        contactMobile: "",
        companyAddress: "",
        license: "",
        expireTime: "",
        remark: "",
        companyStatus: "",
      }, // 弹窗值
    };
  },
  methods: {
    moment,
    add() {
      this.modalVisible = true;
      this.isAdd = true;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = "";
        }
      }
      this.modalFormState.companyId = "idGenerator";
    },
    edit(record) {
      this.modalVisible = true;
      this.isAdd = false;
      this.modalFormState.companyId = record.companyId;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = record[key];
        }
      }
      this.modalFormState.expireTime = moment(record.expireTime, "YYYY-MM-DD");
    },
    submit() {
      this.$refs["modalForm"].validate((valid) => {
        if (valid) {
          const obj = {};
          for (const key in this.modalFormState) {
            if (Object.hasOwnProperty.call(this.modalFormState, key)) {
              obj[key] = this.modalFormState[key];
            }
            // 转换时间格式，datepicker用的是moment，数据库对应的是date
            if (key == "expireTime") {
              obj.expireTime = moment(
                this.modalFormState.expireTime
              ).toISOString(true);
            }
          }
          if (this.isAdd) {
            companyAdd({
              ...this.requestOptions,
              ...obj,
            }).then((res) => {
              const {
                code,
                data: { resList },
              } = res.data;
              if (code === "200") {
                this.initRoom({
                  companyId: resList[0].companyId,
                  license: obj.license,
                });
                this.initJunkWords(obj.companyId);
                this.modalVisible = false;
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "新增成功",
                });
              }
            });
          } else {
            companyUpdate({ ...this.requestOptions, ...obj }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.initRoom({
                  companyId: obj.companyId,
                  license: obj.license,
                });
                this.initJunkWords(obj.companyId);
                this.modalVisible = false;
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "编辑成功",
                });
              }
            });
          }
        }
      });
    },
    del(record) {
      Modal.confirm({
        title: `确定要删除该${this.modalTitle}吗？`,
        content: "注意：此操作无法撤销！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          companyDel({ companyId: record.companyId }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              this.$refs["PagingQueryPage"].query();
              notification.success({
                message: "删除成功",
              });
            }
          });
        },
      });
    },
    // 初始化直播间
    initRoom({ companyId, license }) {
      roomInit({ companyId, license }).then(() => {});
    },
    initJunkWords(companyId) {
      junkWordsInit({ companyId }).then(() => {});
    },
  },
};
</script>

<style></style>
